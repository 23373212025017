export default {
  clientId: '',
  pcEnvironment: 'mypurecloud.com',
  langTag: '',
  redirectUri: 'https://emailfinder.dev.mentat.fr',
  redirectUri_Local: 'http://localhost:8080',
  redirectUri_Dev: 'https://emailfinder.dev.mentat.fr',
  BILLING_API_KEY: 'A2aXs8X!e42AU6+v+8YT$$i=5cHiafXnED',
  APPFOUNDRY_APPLICATION_ID: '81f76d9f-ce43-44db-89c6-30deb3435294'
}
